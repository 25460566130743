/*#cursor {
  border-left: .05em solid #343a40;
  animation: blink .1s steps(2) infinite;
}*/

#cursor {
  animation: 1s infinite;
  margin-left: 1px;
  font-weight: 300;
}

h1 {
  text-align: center;
}