nav.navbar, .navbar-default {
  background-color: #95142D !important; /* #6E3297 */
  margin-bottom: 1em;
}

.navbar-default {
  background-image: none;
  border: none;
  border-radius: 0;
}

.navbar-default .navbar-nav > li > a {
  color: white;
}

.navbar-default .navbar-brand {
  color: white;
}

.navbar-default .navbar-toggle {
  border-color: white;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: white;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: transparent;
}

.navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
  color: white;
  background-color: transparent;
}

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
  color: white;
  background-color: transparent;
}