.post-date {
  font-size: 1em;
  color: black;
}

.thumbnail {
  margin-top: 1em;
  width: 100%;
  height: 100%;
  text-align: center;
}

Link:hover {
  color: black;
}

h2, h2:hover, h2:active, h2:visited {
  color: black;
}