.jumbotron {
  padding: 1em;
  margin-bottom: 2em;
  background-color: #32578F;/*#95142D; /* #6E3297 */ /* #15223F */
}

p {
  font-size: 1.2em;
}

a, a:hover, a:active, a:visited {
  color: black;
}

h3 {
  margin-bottom: 1em;
  text-align: center;
}


code {
  font-family: monospace;
  background-color: black;
  color: whitesmoke;
}
